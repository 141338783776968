<template>
  <div>
    <Header />
    <v-app class="trademark-wrapped">
      <ScrollToTop />
      <div class="trademark-header">
        <small>{{ $t("expertise_uppercase") }} / {{ $t("services") }}</small>
        <h1 class="head-title">{{ $t("trademarks") }}</h1>
      </div>
      <v-container class="trademark-body">
        <p>
          {{ $t("trademark_1") }}
        </p>
        <div class="d-flex flex-column mb-1">
          <div class="d-flex flex-row align-center justify-start">
            <i class="fa-solid fa-angle-right mr-3"></i>
            <p class="ma-0">
              <strong>{{ $t("trademark_2_bold") }}</strong>
            </p>
          </div>
          <ol type="a" class="mx-3">
            <li>
              {{ $t("trademark_2_line_1") }}
            </li>
            <li>
              {{ $t("trademark_2_line_2") }}
            </li>
            <li>
              {{ $t("trademark_2_line_3") }}
            </li>
            <li>
              {{ $t("trademark_2_line_4") }}
            </li>
            <li>{{ $t("trademark_2_line_5") }}</li>
            <li>{{ $t("trademark_2_line_6") }}</li>
          </ol>
        </div>
        <div class="d-flex flex-column mb-1">
          <div class="d-flex flex-row align-center justify-start">
            <i class="fa-solid fa-angle-right mr-3"></i>
            <p class="ma-0">
              <strong>{{ $t("trademark_3_bold") }}</strong>
            </p>
          </div>
          <span class="mx-5"><strong>{{ $t("trademark_3_line_1") }}</strong></span>
          <ol type="a" class="mx-3">
            <li>
              {{ $t("trademark_3_line_2") }}
            </li>
            <li>
              {{ $t("trademark_3_line_3") }}
            </li>
            <li>
              {{ $t("trademark_3_line_4") }}
            </li>
            <li>{{ $t("trademark_3_line_5") }}</li>
          </ol>
          <span><strong>{{ $t("trademark_4_bold") }}</strong></span>
          <ol type="a" class="mx-3">
            <li>
              {{ $t("trademark_4_line_1") }}
            </li>
            <li>
              {{ $t("trademark_4_line_2") }}
            </li>
            <li>
              {{ $t("trademark_4_line_3") }}
            </li>
          </ol>
          <span class="mb-3"><strong>{{ $t("trademark_5_bold") }}</strong></span>
          <span><strong>{{ $t("trademark_6_bold") }}</strong></span>
          <ol type="a" class="mx-3">
            <li>
              {{ $t("trademark_6_line_1") }}
            </li>
            <li>
              {{ $t("trademark_6_line_2") }}
            </li>
          </ol>
        </div>
        <br />
        <div class="d-flex align-center justify-content-center mt-5 mb-1">
          <div class="ma-0 text-center card pa-2">
            <strong>{{ $t("trademark_7_bold") }}</strong>
            <img src="~@/assets/alur-pendaftaran-merek.png" />
          </div>
        </div>
      </v-container>
    </v-app>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header-2";
import Footer from "@/components/Footer";
import ScrollToTop from "@/components/ScrollToTop";

export default {
  name: "Trademark",
  components: {
    Header,
    Footer,
    ScrollToTop
  },
};
</script>

<style scoped>
@font-face {
  font-family: Steelfish;
  src: url("~@/assets/fonts/steelfish-rg.otf");
}

.trademark-wrapped {
  margin-top: 6.5em;
}

.trademark-header {
  background: url("~@/assets/trademark-bg.jpg") no-repeat;
  height: 15em;
  padding: 3em;
  background-color: #212121b3;
  color: #ffffff;
  background-blend-mode: soft-light;
  text-align: center;
  background-position-y: 65%;
}

h1 {
  font-family: "Steelfish", sans-serif;
  font-size: 7em;
}

.fa-angle-right {
  color: #8c3d40;
}

.trademark-body {
  padding: 3em;
  max-width: 75%;
  line-height: 2em;
}

@media screen and (max-width: 480px) {
  .head-title {
    font-size: 5em;
  }

  .trademark-body {
    max-width: 100%;
  }
}
</style>
